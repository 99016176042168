import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../SibeBar";
import { Button, Col, Container, Form, Row, Spinner } from "reactstrap";
import { UtilContext } from "../../contexts/UtilContext";
import useAxiosWrapper from "../../api/axiosWrapper";
import { useNavigate } from "react-router-dom";
import classes from "../Home/Home.module.css";
import { EditableField } from "../Profile/Profile";

function UpdateCharity() {
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { setSelectedCharity, selectedCharity, setUsername, email, username } =
    useContext(UtilContext);
  const axiosInstance = useAxiosWrapper();
  const [suggestedCharitites, setSuggestedCharities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (!res?.name) {
        localStorage.removeItem("user-ai");
        navigate("/signin");
      } else {
        setUsername(res?.name);
      }
      if (res?.charity) {
        setSelectedCharity(res?.charity);
        //   navigate("/create-campaign");
      }
    } else {
      navigate("/signin");
    }
  }, []);

  const handleChange = (e) => {
    setSelectedCharity(null);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setSuggestedCharities([]);
    let delayDebounceFn;
    if (!inputValue || !!selectedCharity) {
      clearTimeout(delayDebounceFn);
      return;
    } else {
      delayDebounceFn = setTimeout(() => {
        setLoading(true);
        axiosInstance
          .get(
            process.env.REACT_APP_DO_GOOD_URL + `/charity/list/${inputValue}`
          )
          .then((result) => {
            const data = result?.data?.data?.slice(0, 7);
            setSuggestedCharities(data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }, 1000);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, selectedCharity]);

  return (
    <SideBar>
      <Container>
        <Row className="justify-content-center mt-4">
          {editable ? (
            <Col md={5}>
              <>
                <>
                  <h5>Let's Update Your Charity</h5>
                  <p>Let's get started by finding your charity.</p>
                </>
                <div>
                  <div>
                    <div className="form-floating mb-3 mt-4 mr-3 w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Enter text"
                        value={selectedCharity?.name || inputValue}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <label htmlFor="floatingInput">Enter Charity Name</label>
                    </div>
                  </div>
                  {!!suggestedCharitites?.length ? (
                    <div className={classes.suggestedCtr}>
                      {suggestedCharitites.map((item, index) => {
                        return (
                          <p
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() => {
                              setSelectedCharity(item);
                              localStorage.setItem(
                                "user-ai",
                                JSON.stringify({
                                  name: username,
                                  charity: item,
                                  email: email,
                                })
                              );
                            }}
                          >
                            {item?.name}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <div style={{ height: 250 }}>
                        <div style={{ textAlign: "center" }} className="pt-3">
                          {loading && <Spinner />}
                        </div>
                      </div>
                    </>
                  )}
                  <Button
                    disabled={!selectedCharity}
                    style={{
                      backgroundColor: !selectedCharity ? "gray" : "black",
                      marginTop: 30,
                    }}
                    className="w-100"
                    onClick={() => setEditable(false)}
                  >
                    {" "}
                    Next
                  </Button>
                </div>
              </>
            </Col>
          ) : (
            <Col md={5}>
              <>
                <h5 className="mb-5">Charity</h5>
                <Form className="row gap-2">
                  <div className="floating-label-group col-9 p-0 mr-4">
                    <input
                      type="text"
                      name={"charity"}
                      id={"charity"}
                      className="floating-label-input read-only-input"
                      value={selectedCharity?.name}
                      readOnly
                      placeholder=" "
                    />
                    <label className="floating-label" htmlFor={"charity"}>
                      Charity Name
                    </label>
                  </div>
                  <Button
                    outline
                    className="col-2 ml-2"
                    color="primary"
                    onClick={() => {
                      setEditable(true);
                    }}
                  >
                    Edit
                  </Button>
                </Form>
              </>
            </Col>
          )}
        </Row>
      </Container>
    </SideBar>
  );
}

export default UpdateCharity;

// MainApp.js

import React, { Children, useContext, useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
} from "reactstrap";
import Menu from "./assets/menu.svg";
import { FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useAxiosWrapper from "./api/axiosWrapper";
import { UtilContext } from "./contexts/UtilContext";
import { isMobile } from "react-device-detect";

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const axiosInstance = useAxiosWrapper();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const {
    selectedCharity,
    setUsername,
    username,
    email,
    setSelectedCharity,
    setEmail,
  } = useContext(UtilContext);

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    console.log("ppp");
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (res?.charity) {
        setSelectedCharity(res?.charity);
      }
      if (res?.name) {
        setUsername(res?.name);
      }
      if (res?.email) {
        setEmail(res?.email);
      }
    }
  }, [username]);

  // const fetchUser = async () => {
  //   try {
  //     const response = await axiosInstance.get(
  //       process.env.REACT_APP_DO_GOOD_URL + "/user/me"
  //     );
  //     console.log(response?.data?.data, "user response");
  //     const userInfo = response?.data?.data;
  //     // if (userInfo) {
  //     //   localStorage.setItem(
  //     //     "user-ai",
  //     //     JSON.stringify({
  //     //       name:
  //     //         response?.data?.data?.first_name +
  //     //         " " +
  //     //         response?.data?.data?.last_name,
  //     //       charity: selectedCharity,
  //     //     })
  //     //   );
  //     //   setUsername(
  //     //     response?.data?.data?.first_name +
  //     //       " " +
  //     //       response?.data?.data?.last_name
  //     //   );
  //     // }
  //   } catch {
  //     setMessage("Something went wrong. Please try again");
  //   }
  // };

  const getInitials = (fullName) => {
    console.log(fullName, "fullnam");
    const allNames = fullName?.trim().split(" ");
    const initials = allNames?.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
    return initials;
  };

  return (
    <div>
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        {!isOpen && !isMobile && (
          <div outline onClick={toggleSidebar} className="toggle-button">
            <img src={Menu} alt="menu" />
          </div>
        )}
        {!isOpen && isMobile && (
          <>
            <div className="header">
              <div
                outline
                onClick={toggleSidebar}
                className="toggle-button-mb"
                style={{ display: "relative" }}
              >
                <img src={Menu} alt="menu" />
              </div>
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 100,
                  backgroundColor: "#ff5344",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  right: 20,
                }}
              >
                <div>{getInitials(username)}</div>
              </div>
            </div>
          </>
        )}
        {isOpen && (
          <Navbar color="faded" light className="d-flex flex-column w-100">
            <Nav navbar className="px-2 w-100">
              <Row className="mt-4 align-items-center">
                <Col xs={6}>
                  <h5>Menu</h5>
                </Col>
                <Col xs={6} style={{}}></Col>
              </Row>

              <Row className="mt-4 align-items-center gap-2">
                <Col xs={2}>
                  <div
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 100,
                      backgroundColor: "#ff5344",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    <div>{getInitials(username)}</div>
                  </div>
                </Col>
                <Col xs={9} style={{ fontSize: 12, paddingLeft: 8 }}>
                  <b>{username}</b>
                  <div style={{ fontSize: 12, color: "#a0a3bd" }}>{email}</div>
                </Col>
              </Row>
              {selectedCharity?.name && (
                <Button
                  className="mt-4 border-0 text-start rounded-0"
                  outline
                  onClick={() => navigate("/update-charity")}
                >
                  <Row>
                    <Col>
                      <div>Charity</div>
                      <div style={{ fontSize: 12 }}>
                        {selectedCharity?.name}
                      </div>
                    </Col>
                  </Row>
                </Button>
              )}
              <Button
                className="mt-4 border-0 text-start rounded-0"
                outline
                onClick={() => navigate("/profile")}
              >
                <Row className="align-items-center">
                  <Col xs={2}>
                    <FiUser size={26} />
                  </Col>
                  <Col>
                    <div style={{ fontSize: 12 }}>Your Account</div>
                  </Col>
                </Row>
              </Button>
              <Button className="mt-4 border-0 text-start rounded-0" outline>
                <Row className="align-items-center">
                  <Col>
                    <div
                      style={{ fontSize: 12 }}
                      onClick={async () => {
                        await axiosInstance.get(
                          process.env.REACT_APP_DO_GOOD_URL + "/user/logout"
                        );
                        localStorage.removeItem("user-ai");
                        setUsername(null);
                        setEmail(null);
                        setSelectedCharity(null);
                        navigate("/");
                      }}
                    >
                      Logout
                    </div>
                  </Col>
                </Row>
              </Button>
            </Nav>
          </Navbar>
        )}
      </div>
      <div
        className={`overlay ${isOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      ></div>
      <div className={`main-content ${isOpen ? "" : ""}`}>{children}</div>
      {/* Main App navigation or layout */}
    </div>
  );
};

export default SideBar;

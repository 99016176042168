// src/components/SignIn.js
import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useAxiosWrapper from "../../api/axiosWrapper";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./SignIn.module.css";
import { FaAngleLeft } from "react-icons/fa6";
import { isMobile } from "react-device-detect";
import SideBar from "../../SibeBar";
import google from "../../assets/google.svg";

const SignIn = () => {
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosWrapper();
  const navigate = useNavigate();
  const { selectedCharity, setSelectedCharity, setUsername, setEmail } =
    useContext(UtilContext);

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (res?.charity) {
        setSelectedCharity(res?.charity);
        navigate("/create-campaign");
      } else if (res?.name) {
        navigate("/home");
      }
    }
  }, []);

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        process.env.REACT_APP_DO_GOOD_URL + "/user/login",
        {
          email: emailId,
          password,
        }
      );
      if (!!response?.data?.data?.id) {
        localStorage.setItem(
          "user-ai",
          JSON.stringify({
            name: response?.data?.data?.first_name,
            charity: selectedCharity,
            email: emailId,
          })
        );
        setUsername(
          response?.data?.data?.first_name +
            " " +
            response?.data?.data?.last_name
        );
        setEmail(emailId);
        setMessage("Sign-in successful!");
        navigate("/home");
      } else {
        setMessage(response.message || "Sign-in failed.");
      }
      setLoading(false);
    } catch (error) {
      setMessage("Sign-in failed.");
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="justify-content-center align-items-center h-100">
        <div onClick={() => navigate(-1)} className="back">
          <FaAngleLeft />
        </div>
        <Col className="">
          <div
            className="d-flex justify-content-center w-100 container"
            style={{ marginTop: isMobile ? 120 : 0 }}
          >
            <form
              onSubmit={handleSignIn}
              style={{ width: isMobile ? 320 : 360 }}
            >
              <h3>Sign In!</h3>
              <div className="form-floating mb-3 mt-4">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  required
                  placeholder="Enter Email"
                />
                <label htmlFor="email" className="form-label">
                  Email
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Enter password"
                />
                <label htmlFor="password" className="form-label">
                  Password
                </label>
              </div>
              <div style={{ textAlign: "end", fontSize: 14 }} className="mb-2">
                Create a new account?
                <span
                  onClick={() => navigate("/signup")}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "#ff5344",
                  }}
                >
                  {" "}
                  Sign up!
                </span>
              </div>
              <button
                type="submit"
                className="btn btn-secondary"
                style={{ backgroundColor: "#ff5344", width: "100%" }}
              >
                Sign In
              </button>
              <button
                type="button"
                className="btn bg-dark mt-2"
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_AI_DO_GOOD_URL +
                    "/oauth/google/login")
                }
                style={{ color: "white", width: "100%" }}
              >
                <img
                  src={google}
                  alt="google"
                  height={20}
                  width={20}
                  className="mr-3"
                />
                <span
                  className="ml-3"
                  style={{ display: "inline-block", marginLeft: 8 }}
                >
                  Sign in with Google
                </span>
              </button>
              {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner />
                </div>
              )}
              <div
                style={{
                  width: 360,
                  textAlign: "center",

                  justifyContent: "center",
                }}
              >
                {message && (
                  <div className="alert alert-info mt-3">{message}</div>
                )}
              </div>
            </form>
          </div>
        </Col>
        <Col className={`d-none d-md-flex ${classes.imgCtr}`}>
          <img
            src={"confirm.png"}
            alt=""
            style={{ height: 260, width: 494, objectFit: "contain" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SignIn;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./screens/Home/Home";
import ConfirmCharity from "./screens/ConfirmCharity/ConfirmCharity";
import CreateCampaign from "./screens/CreateCampaign/CreateCampaign";
import Suggestions from "./screens/Suggestions/Suggestions";
import CampaignSuggestions from "./screens/CampaignSuggestions/CampaignSuggestions";
import { UtilProvider } from "./contexts/UtilContext";
import SignIn from "./screens/SignIn/SignIn";
import SignUp from "./screens/SignUp/SignUp";
import AuthProvider from "./contexts/AuthContext";
import EmailTemplate from "./screens/EmailTemplate/EmailTemplate";
import Complete from "./screens/Complete/Complete";
import ProfilePage from "./screens/Profile/Profile";
import GoogleCallback from "./screens/GoogleCallback/GoogleCallback";
import UpdateCharity from "./screens/UpdateCharity/UpdateCharity";

function App() {
  return (
    <AuthProvider>
      <UtilProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dgp-ai/callback" element={<GoogleCallback />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/home" element={<Home />} />
            <Route path="/confirm-charity" element={<ConfirmCharity />} />
            <Route path="/update-charity" element={<UpdateCharity />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/create-campaign" element={<CreateCampaign />} />
            <Route path="/Suggestions" element={<Suggestions />} />
            <Route path="/email-template" element={<EmailTemplate />} />
            <Route path="/complete" element={<Complete />} />
            {/* <Route
              path="/dgp-ai/oauth/google/callback"
              element={<GoogleCallback />}
            /> */}
            <Route
              path="/campaign-suggestions"
              element={<CampaignSuggestions />}
            />
          </Routes>
        </Router>
      </UtilProvider>
    </AuthProvider>
  );
}

export default App;

import { Col, Container, Row } from "reactstrap";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Calender from "../../assets/calandar.svg";
import Notification from "../../assets/bullhorn.svg";
import Chart from "../../assets/donors.svg";
import { useContext, useEffect } from "react";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./CreateCampaign.module.css";
import { isMobile } from "react-device-detect";
import SideBar from "../../SibeBar";

export default function CreateCampaign() {
  const navigate = useNavigate();
  const { setSelectedTheme } = useContext(UtilContext);

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);

      if (!res?.name) {
        // localStorage.removeItem("user-ai");
        navigate("/signin");
      }
      if (!res?.charity) {
        navigate("/create-campaign");
      }
    } else {
      navigate("/signin");
    }
  }, []);

  return (
    <SideBar>
      <Container>
        <Row className="justify-content-center align-items-center h-100">
          <Col className="">
            <div onClick={() => navigate("/")} className="back">
              <FaAngleLeft />
            </div>
          </Col>
          <Row className="justify-content-center">
            <Col md={4}>
              <div style={{ marginTop: isMobile ? 120 : 200, fontWeight: 600 }}>
                Let’s create a campaign! Choose the type of campaign you'd like
                to create.
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center gap-4 mt-5">
            <Col
              onClick={() => {
                setSelectedTheme("holiday");
                navigate("/suggestions");
              }}
              md={2}
              style={{
                backgroundColor: "#f16e6e",
              }}
              className={classes.card}
            >
              <img src={Calender} alt="" />
              <div
                style={{ fontSize: 13, color: "white", fontWeight: "bold" }}
                className="mt-2"
              >
                Holiday
              </div>
              <div className={`mt-2 ${classes.txt}`}>
                New Year, Memorial Day, etc.
              </div>
            </Col>
            <Col
              onClick={() => {
                setSelectedTheme("marketing");
                navigate("/suggestions");
              }}
              md={2}
              className={classes.card}
              style={{
                backgroundColor: "#c36eec",
              }}
            >
              <img src={Notification} alt="" />
              <div
                style={{ fontSize: 13, color: "white", fontWeight: "bold" }}
                className="mt-2"
              >
                Marketing
              </div>
              <div className={`mt-2 ${classes.txt}`}>
                National dog day, Ice cream day, etc
              </div>
            </Col>
            <Col
              onClick={() => {
                setSelectedTheme("donor_engagement");
                navigate("/suggestions");
              }}
              md={2}
              className={classes.card}
              style={{
                backgroundColor: "#7377d2",
              }}
            >
              <img src={Chart} alt="" />
              <div
                style={{ fontSize: 13, color: "white", fontWeight: "bold" }}
                className="mt-2"
              >
                Donor Engagement
              </div>
              <div className={`mt-2 ${classes.txt}`}>
                Impact report, Project Updates, etc.
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </SideBar>
  );
}

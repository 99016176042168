// src/api/axiosWrapper.js
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const useAxiosWrapper = () => {
  const { authToken } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DO_GOOD_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  //Add a response interceptor to handle errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error.response ? error.response.data : error);
    }
  );

  return axiosInstance;
};

export default useAxiosWrapper;

import { Col, Container, Row, Spinner } from "reactstrap";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Calender from "../../assets/CalandarBlack.png";
import { FiRefreshCcw } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./CampaignSuggestion.module.css";
import useAxiosWrapper from "../../api/axiosWrapper";
import { isMobile } from "react-device-detect";
import SideBar from "../../SibeBar";

export default function CampaignSuggestions() {
  const navigate = useNavigate();
  const [campaignEvents, setCampaignEvents] = useState(null);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosWrapper();
  const [loading, setLoading] = useState(false);

  const {
    selectedTheme,
    selectedCharity,
    setSelectedIdea,
    selectedSuggestion,
    username,
  } = useContext(UtilContext);

  useEffect(() => {
    if (!username) {
      navigate("/");
      return;
    }
    if (!selectedTheme || !selectedCharity || !selectedSuggestion) {
      navigate("/");
      return;
    }
    fetchData();
  }, [selectedSuggestion, selectedTheme, selectedCharity?.id]);

  const fetchData = async () => {
    const url = process.env.REACT_APP_DO_GOOD_URL + "/campaign/email/ideas";

    const body = {
      event: selectedSuggestion,
      // theme: selectedTheme,
      // charity_id: selectedCharity?.id,
    };

    try {
      setLoading(true);
      const response = await axiosInstance.post(url, body);
      setCampaignEvents(response?.data?.data?.data?.ideas);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <SideBar>
      <Container>
        <Row className="justify-content-center align-items-center h-100">
          <Col className="">
            <div onClick={() => navigate(-1)} className="back">
              <FaAngleLeft />
            </div>
          </Col>
          <Row className="justify-content-center">
            <Col md={4}>
              <div style={{ marginTop: 100, fontWeight: 600 }}>
                Awesome! Here are a few campaign suggestions for{" "}
                {selectedSuggestion}.
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center gap-4 mt-5">
            {loading ? (
              <Spinner />
            ) : (
              <>
                {campaignEvents?.map((item, index) => {
                  return (
                    <Col md={3} className={`card ${classes.crd}`} key={index}>
                      <img
                        src={item?.image?.landscape}
                        alt=""
                        style={{ height: 137, objectFit: "cover" }}
                      />
                      <div className={classes.ctr}>
                        <img
                          src={Calender}
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                        <div className={classes.lines}>
                          <div style={{ fontWeight: "bold" }} className="mt-2">
                            {item?.idea.split(":")?.[0]}
                          </div>

                          <div className="card-text">
                            {item?.idea.split(":")?.[1]}
                          </div>
                        </div>

                        <button
                          className="btn btn-dark mt-2 w-100 hidden-button"
                          onClick={() => {
                            setSelectedIdea(item?.idea);
                            navigate("/email-template");
                          }}
                        >
                          View Campaign
                        </button>

                        <div className="mt-1" style={{ fontSize: 15 }}></div>
                      </div>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
          <Row
            className="justify-content-center text-center"
            style={{ marginTop: isMobile ? 50 : 300 }}
          >
            <Col
              sm={2}
              className="d-flex align-items-center justify-content-center"
              style={{ cursor: "pointer" }}
              onClick={fetchData}
            >
              <FiRefreshCcw />
              <div style={{ marginLeft: 12 }}>Regenerate</div>
            </Col>
          </Row>
        </Row>
      </Container>
    </SideBar>
  );
}

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

const GoogleCallback = () => {
  const naviagte = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code"); // Assuming the token is passed as a query parameter
    const state = params.get("state"); // Assuming the token is passed as a query parameter
    console.log("code", code);
    console.log("state", state);
    // if (token) {
    //   // Send code to your backend for token exchange
    axios
      .get("/dgp-ai/oauth/google/callback", { params })
      .then((response) => {
        //       // Handle response from backend
        console.log("User info:", response.data);
      })
      .catch((error) => {
        console.errzor("Error:", error);
      });
    // }
    // if (token) {
    //   Cookies.set("auth_token", token); // Store the token in cookies
    //   naviagte("/"); // Redirect to the home page or another protected page
    // } else {
    //   // Handle error
    //   console.error("No token found");
    // }
  }, []);

  return <div>Loading...</div>;
};

export default GoogleCallback;

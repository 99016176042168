import { createContext, useState } from "react";

export const UtilContext = createContext();

export const UtilProvider = ({ children }) => {
  const [selectedCharity, setSelectedCharity] = useState("");
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);

  return (
    <UtilContext.Provider
      value={{
        selectedCharity,
        setSelectedCharity,
        setSelectedTheme,
        selectedTheme,
        setSelectedSuggestion,
        selectedSuggestion,
        selectedIdea,
        setSelectedIdea,
        username,
        setUsername,
        email,
        setEmail,
      }}
    >
      {children}
    </UtilContext.Provider>
  );
};
